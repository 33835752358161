@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.gradient {
  background: linear-gradient(
    to bottom right,
  #001533 10%,
    /* colors.gradient.third */ #003366 60%,
    /* colors.gradient.second */ #005599 100% /* colors.gradient.first */
  );
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root .rhap_container {
  box-shadow: none;
  background-color: transparent;
}

#root .rhap_time {
  color: rgb(107 114 128);
  font-size: small;
}

#root .rhap_progress-section {
  margin-top: -35px;
}

#root .voicebox {
  .rhap_container {
    box-shadow: none;
    background-color: transparent;
  }

  .rhap_time {
    color: rgb(107 114 128);
    font-size: small;
  }

  .rhap_progress-container {
    opacity: 100;
  }

  .rhap_progress-section {
    margin-top: 0px;
  }

  .rhap_play-pause-button {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
}

.ProseMirror {
  @apply focus:outline-none w-[768px] p-2;
}

.button-store-name {
  text-wrap: nowrap;
}

/* Range */ 
/* Base range input */
.range-input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;
  margin: 0;
}

/* Removing default focus styles */
.range-input:focus {
  outline: none;
}

/* Track styling for Chrome/Safari */
.range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: linear-gradient(to right, #4a90e2 var(--range-progress, 0%), #ddd var(--range-progress, 0%));
  border-radius: 3px;
  border: none;
}

/* Track styling for Firefox */
.range-input::-moz-range-track {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 3px;
  border: none;
}

/* Progress fill for Firefox */
.range-input::-moz-range-progress {
  height: 6px;
  background: #4a90e2;
  border-radius: 3px;
}

/* Thumb styling for Chrome/Safari */
.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #4a90e2;
  margin-top: -5px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: background 0.15s ease, border-color 0.15s ease;
  position: relative;
  z-index: 1;
}

/* Thumb styling for Firefox */
.range-input::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #4a90e2;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: background 0.15s ease, border-color 0.15s ease;
  position: relative;
  z-index: 1;
}

/* Hover states */
.range-input:hover::-webkit-slider-thumb {
  background: #4a90e2;
  border-color: #4a90e2;
}

.range-input:hover::-moz-range-thumb {
  background: #4a90e2;
  border-color: #4a90e2;
}

/* Focus states */
.range-input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(74,144,226,0.3);
}

.range-input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(74,144,226,0.3);
}

/* Disabled state */
.range-input:disabled {
  opacity: 0.6;
}

.range-input:disabled::-webkit-slider-thumb {
  background: #fff;
  border-color: #999;
  cursor: not-allowed;
}

.range-input:disabled::-moz-range-thumb {
  background: #fff;
  border-color: #999;
  cursor: not-allowed;
}

/* Summary */
.summary {
  p {
    margin-bottom: 1.5rem;
  }
}